import RooVideo from '../assets/videos/roo_croped.mp4';

function Buy() {
    return (
        <>
            <div className="flex flex-col gap-20 w-full justify-center items-center">
                <div className="bg-[#000000ce] w-full h-screen absolute backdrop-blur-lg flex justify-center items-center">
                    <p className='text-xl md:text-6xl font-black'>COMING SOON!</p>
                </div>
                <video className='py-4 md:py-12' autoPlay loop muted controls={false}>
                    <source src={RooVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </>
    );
}

export default Buy;