import { NavLink } from "react-router-dom";
import BirdeyeIcon from '../assets/images/birdeye.png';

function Navbar() {
    return (
        <>
            <header className="sticky top-0 py-5 w-full bg-black z-50 overflow-hidden">
                <div className="flex flex-row justify-center md:justify-between items-center md:px-[60px]">
                    <div className="w-auto h-[70px] flex flex-row gap-6 items-center">
                        <NavLink className="block md:block" to="/">
                            <img className="w-[80px] h-[80px] block md:block" src="logo.png" alt="Logo" />
                        </NavLink>
                        <NavLink to="/" className="text-[12px] sm:text-xs md:text-sm lg:text-md">Roidsroo</NavLink>
                        <NavLink to="/roo" className="text-[12px] sm:text-xs md:text-sm lg:text-md">Is jus a roo</NavLink>
                        
                    </div>
                    <div className="flex-row gap-4 hidden md:flex items-center">
                        <a className="cursor-pointer" target="_blank" rel="noreferrer" href="https://x.com/roidsroo?s=21&t=ffxa__mFbalwO0ud53lNNQ">
                            <svg className="w-10 h-10" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_72_2499)">
                                    <path d="M0 100V0H100V100H0Z" fill="black" />
                                    <path d="M22 8C14.268 8 8 14.268 8 22V78C8 85.732 14.268 92 22 92H78C85.732 92 92 85.732 92 78V22C92 14.268 85.732 8 78 8H22ZM26.1719 26H42.0469L53.3203 42.0195L67 26H72L55.5781 45.2266L75.8281 74H59.957L46.875 55.4141L31 74H26L44.6172 52.207L26.1719 26ZM33.8281 30L62.043 70H68.1719L39.957 30H33.8281Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_72_2499">
                                        <rect width="100" height="100" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>

                        </a>
                        <a className="flex justify-center items-center bg-white w-[34px] h-[34px] rounded-md cursor-pointer" target="_blank" rel="noreferrer" href="https://birdeye.so/token/7agFWphxVZ4qH3eV3wr6WQswrsYvt6636knLcb6CVNfC?chain=solana">
                            <img className="w-8 h-8" src={BirdeyeIcon} alt="Birdeye"/>
                        </a>
                    </div>
                </div>
                <div className='w-full h-auto overflow-hidden'>
                    <div className="absolute w-[5000px] h-auto bg-[#0066FF] backdrop-blur-sm left-0 flex items-center">
                        <div className='top-text-slide flex flex-row gap-6 items-center justify-between'>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                            <p className="capitalize">roidsroo is jus a kangaroo on roids</p>
                            <p>⭐</p>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Navbar;