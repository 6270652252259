import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "./styles.css";
import { Navigation } from "swiper";
import { Pagination } from "swiper";

import Roo1 from '../assets/images/roo_1.jpg';
import Roo2 from '../assets/images/roo_2.webp';
import Roo3 from '../assets/images/roo_3.jpg';
import Roo4 from '../assets/images/roo_4.png';
import Roo5 from '../assets/images/roo_5.jpg';
import Roo6 from '../assets/images/roo_6.webp';
import Roo7 from '../assets/images/roo_7.jpg';
import Roo8 from '../assets/images/roo_8.jpeg';
import Roo9 from '../assets/images/roo_9.jpg';
import Roo10 from '../assets/images/roo_10.jpg';
import Roo11 from '../assets/images/roo_11.jpg';

const imageList = [Roo1, Roo2, Roo3, Roo4, Roo5, Roo6, Roo7, Roo8, Roo9, Roo10, Roo11];
function Skills() {
    return (
        <>
            <p className="mt-24 md:text-7xl font-extrabold">Roidsroo Class Of 2023</p>
            <div id="skills" className="w-full wrapper h-full bg-no-repeat bg-center bg-cover">
                <div className="skills-wrapper text-center text-white">
                    <div className="box flex justify-between items-center my-6">
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={20}
                            pagination={{
                                clickable: true,
                            }}
                            navigation={true}
                            modules={[Navigation, Pagination]}
                            className="mySwiper"
                        >

                            {imageList.map((item) => {
                                return (
                                    <SwiperSlide className="">
                                        {" "}
                                        <div className="w-full">
                                            <img className="object-cover object-top" src={item} alt="" />
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Skills;