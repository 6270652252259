import { NavLink } from "react-router-dom";
import BirdeyeIcon from '../assets/images/birdeye.png';

function Footer() {
    return (
        <footer className='mt-[200px] w-full h-auto py-12 flex justify-center items-center border-t-[1px]'>
            <div className="flex flex-col md:flex-row justify-between items-center md:gap-12 md:px-[60px]">
                <div className="flex flex-col md:flex-row md:gap-6 items-center">
                    <NavLink to="/">
                        <img className="w-[100px] h-[100px]" src="logo.png" alt="Logo" />
                    </NavLink>
                    <div className="flex gap-4 items-center">
                        <a className="cursor-pointer" target="_blank" rel="noreferrer" href="https://x.com/roidsroo?s=21&t=ffxa__mFbalwO0ud53lNNQ">
                            <svg className="w-10 h-10" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_72_2499)">
                                    <path d="M0 100V0H100V100H0Z" fill="black" />
                                    <path d="M22 8C14.268 8 8 14.268 8 22V78C8 85.732 14.268 92 22 92H78C85.732 92 92 85.732 92 78V22C92 14.268 85.732 8 78 8H22ZM26.1719 26H42.0469L53.3203 42.0195L67 26H72L55.5781 45.2266L75.8281 74H59.957L46.875 55.4141L31 74H26L44.6172 52.207L26.1719 26ZM33.8281 30L62.043 70H68.1719L39.957 30H33.8281Z" fill="white" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_72_2499">
                                        <rect width="100" height="100" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a className="flex justify-center items-center bg-white w-[34px] h-[34px] rounded-md cursor-pointer" target="_blank" rel="noreferrer" href="https://birdeye.so/token/7agFWphxVZ4qH3eV3wr6WQswrsYvt6636knLcb6CVNfC?chain=solana">
                            <img className="w-8 h-8" src={BirdeyeIcon} alt="Birdeye"/>
                        </a>
                    </div>
                </div>
                <div className="w-[70%]">
                    <p className="text-center md:text-start capitalize">this is jus a memecoin with a kangaroo on roids, nothing serious. stop yapping and start buying or dis kangaroo will come get you</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;