import ImageSwiper from '../components/ImageSwiper';

import VideoSection from '../components/VideoSection';
function Roo() {
    return (
        <>
            <main className="flex flex-col gap-4 md:gap-28 items-center justify-between p-3 sm:p-6 md:p-12 lg:p-24">
                <VideoSection />
                <ImageSwiper />
            </main>
        </>
    );
}

export default Roo;