import RooVideo from '../assets/videos/roo_croped.mp4';

function VideoSection() {
    return (
        <section className="w-full">
            <div className="flex flex-col gap-20 w-full justify-center items-center">
                <p className='text-center text-2xl lg:text-6xl flex'>Watch dis video <p className='animate-bounce'>👇</p></p>
                <video autoPlay loop muted controls className='w-full h-auto'>
                    <source src={RooVideo} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <p className="text-center text-lg lg:text-4xl capitalize break-words w-full">$roo on solana because it's the best layer 1, simple as that. RooooOOOOoooOOOOOooooooOOOOOOOOOOOOooooooOOOOooooooOO</p>
            </div>
        </section>
    );
}

export default VideoSection;