import Roo1 from '../assets/images/roo_1.jpg'
import Roo2 from '../assets/images/roo_2.webp'
import Roo3 from '../assets/images/roo_3.jpg'
import Roo4 from '../assets/images/roo_4.png'
import ImageSwiper from '../components/ImageSwiper';

import VideoSection from '../components/VideoSection';
function Home() {
    return (
        <>
            <main className="flex min-h-screen flex-col gap-8 md:gap-28 items-center justify-between p-3 sm:p-6 md:p-12 lg:p-24">
                <section className='w-full'>
                    <h1 className='text-2xl sm:text-5xl lg:text-7xl text-center '>Roids <span className='text-[#0066FF] font-black'>$Roo</span> Is Jus a Kangaroo On Roids</h1>
                </section>
                <section className="w-full">
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-0 w-full h-auto items-start">
                        <div className="flex flex-col gap-3 justify-center items-center my-4">
                            <img
                                src={Roo1}
                                className="w-[80%] md:w-full h-[300px] md:h-[600px] object-cover object-top"
                                alt="The Lazy Roo"
                            />
                            <p className="text-xs md:md lg:text-lg text-center">The Lazy Roo'</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center my-4">
                            <img
                                src={Roo2}
                                className="w-[80%] md:w-full h-[300px] md:h-[600px] object-cover object-top"
                                alt="The I Always Lift Roo"
                            />
                            <p className="text-xs md:md lg:text-lg text-center">The I Always Lift Roo'</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center my-4">
                            <img
                                src={Roo3}
                                className="w-[80%] md:w-full h-[300px] md:h-[600px] object-cover object-top"
                                alt="The Chase If You Can Roo"
                            />
                            <p className="text-xs md:md lg:text-lg text-center">The Chase Me If You Can Roo'</p>
                        </div>
                        <div className="flex flex-col gap-3 justify-center items-center my-4">
                            <img
                                src={Roo4}
                                className="w-[80%] md:w-full h-[300px] md:h-[600px] object-cover object-top"
                                alt="Roo The Dawg"
                            />
                            <p className="text-xs md:md lg:text-lg text-center">Roo The Dawg'</p>
                        </div>
                    </div>
                </section>
                <VideoSection />
                <ImageSwiper />
            </main>
        </>
    );
}

export default Home;