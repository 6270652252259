import './App.css';
import './fonts.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import Navbar from './layouts/Navbar';
import Footer from './layouts/Footer';

import './swiper.css';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/thumbs';
import 'swiper/css/effect-coverflow';
import 'swiper/css/mousewheel';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/grid';
import Roo from './pages/Roo';
import Buy from './pages/Buy';
function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/roo' element={<Roo />} />
          <Route path='/buy' element={<Buy />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
